import { SystemProps } from '@storyofams/react-ui';
import dynamic from 'next/dynamic';
import { TermsandconditionsComponent } from '~lib/storyblok/sdk';

import type {
  ContactForm as ContactFormType,
  Faq as FaqType,
  ImageSection as ImageSectionType,
  Quote as QuoteType,
  TextImage as TextImageType,
  TitleText as TitleTextType,
  VideoSection as VideoSectionType,
  Header as HeaderType,
  LinkButton as LinkButtonType,
  TopBanner as TopBannerType,
  Banner as BannerType,
  ReviewsSmall as ReviewsSmallType,
  GridHome as GridHomeType,
  GridProductsHome as GridProductsHomeType,
  SamplePackage as SamplePackageType,
  Hero as HeroType,
  FeaturedProduct as FeaturedProductType,
  HowToSection as HowToSectionType,
  FaqSection as FaqSectionType,
  HighlightOfTheWeek as HighlightOfTheWeekType,
  GridProductsHomeV2 as GridProductsHomeV2Type,
  TbpdpDetails as TbpdpDetailsType,
  HeroV2 as HeroV2Type,
  DynamicButton as DynamicButtonType
} from './sections';

const ContactForm = dynamic(() =>
  import('./sections/ContactForm').then((mod) => mod.ContactForm),
) as typeof ContactFormType;
const Faq = dynamic(() =>
  import('./sections/Faq').then((mod) => mod.Faq),
) as typeof FaqType;
const Header = dynamic(() =>
  import('./sections/Header').then((mod) => mod.Header),
) as typeof HeaderType;
const ImageSection = dynamic(() =>
  import('./sections/ImageSection').then((mod) => mod.ImageSection),
) as typeof ImageSectionType;
const Quote = dynamic(() =>
  import('./sections/Quote').then((mod) => mod.Quote),
) as typeof QuoteType;
const TextImage = dynamic(() =>
  import('./sections/TextImage').then((mod) => mod.TextImage),
) as typeof TextImageType;
const TitleText = dynamic(() =>
  import('./sections/TitleText').then((mod) => mod.TitleText),
) as typeof TitleTextType;
const VideoSection = dynamic(() =>
  import('./sections/VideoSection').then((mod) => mod.VideoSection),
) as typeof VideoSectionType;
const LinkButton = dynamic(() =>
  import('./sections/LinkButton').then((mod) => mod.LinkButton),
) as typeof LinkButtonType;
const TopBanner = dynamic(() =>
  import('./sections/TopBanner').then((mod) => mod.TopBanner),
) as typeof TopBannerType;
const Banner = dynamic(() =>
  import('./sections/Banner').then((mod) => mod.Banner),
) as typeof BannerType;
const ReviewsSmall = dynamic(() =>
  import('./sections/ReviewsSmall').then((mod) => mod.ReviewsSmall),
) as typeof ReviewsSmallType;
const GridHome = dynamic(() =>
  import('./sections/GridHome').then((mod) => mod.GridHome),
) as typeof GridHomeType;
const GridProductsHome = dynamic(() =>
  import('./sections/GridProductsHome').then((mod) => mod.GridProductsHome),
) as typeof GridProductsHomeType;
const SamplePackage = dynamic(() =>
  import('./sections/Home-2-0/SamplePackage').then((mod) => mod.SamplePackage),
) as typeof SamplePackageType;
const Hero = dynamic(() =>
  import('./sections/Home-2-0/Hero').then((mod) => mod.Hero),
) as typeof HeroType;
const FeaturedProduct = dynamic(() =>
  import('./sections/Home-2-0/FeaturedProduct').then((mod) => mod.FeaturedProduct),
) as typeof FeaturedProductType;
const HowToSection = dynamic(() =>
  import('./sections/Home-2-0/HowToSection').then((mod) => mod.HowToSection),
) as typeof HowToSectionType;
const FaqSection = dynamic(() =>
  import('./sections/Home-2-0/FaqSection').then((mod) => mod.FaqSection),
) as typeof FaqSectionType;
const HighlightOfTheWeek = dynamic(() =>
  import('./sections/Home-2-0/HighlightOfTheWeek').then((mod) => mod.HighlightOfTheWeek),
) as typeof HighlightOfTheWeekType;
const GridProductsHomeV2 = dynamic(() =>
  import('./sections/Home-2-0/GridProductsHomeV2').then((mod) => mod.GridProductsHomeV2),
) as typeof GridProductsHomeV2Type;
const TbpdpDetails = dynamic(() =>
  import('./sections/Tbpdp/TbpdpDetails').then((mod) => mod.TbpdpDetails),
) as typeof TbpdpDetailsType;
const HeroV2 = dynamic(() =>
  import('./sections/Home-2-0/HeroV2').then((mod) => mod.HeroV2),
) as typeof HeroV2Type;
const DynamicButton = dynamic(() =>
  import('./sections/DynamicButton').then((mod) => mod.DynamicButton),
) as typeof DynamicButtonType;

const Components = {
  header: Header,
  contact_form: ContactForm,
  faq_dynamic: Faq,
  image: ImageSection,
  quote: Quote,
  rich_text: TextImage,
  text_image: TextImage,
  title_description: TitleText,
  video_dynamic: VideoSection,
  LinkButton: LinkButton,
  top_banner: TopBanner,
  banner: Banner,
  reviews_small: ReviewsSmall,
  grid_home: GridHome,
  grid_products_home: GridProductsHome,
  sample_package: SamplePackage,
  hero: Hero,
  hero_v2: HeroV2,
  'Featured product': FeaturedProduct,
  'how-2-section': HowToSection,
  faq_section: FaqSection,
  highlight_of_the_week: HighlightOfTheWeek,
  grid_products_home_v2: GridProductsHomeV2,
  tbpdp_details: TbpdpDetails,
  dynamicButton: DynamicButton,
};

export interface DynamicSectionsProps extends SystemProps {
  termsAndConditions?: TermsandconditionsComponent;
  content?: any;
  disableFirst?: boolean;
}

export const DynamicSections = ({
  content,
  disableFirst,
  ...props
}: DynamicSectionsProps) => {
  const firstIndex = content?.sections?.findIndex(section => section?.title || section?.quote)

  return content?.sections?.length
    ? content?.sections?.map((section, i) => {
        if (
          !!section &&
          typeof Components[section?.component] !== 'undefined'
        ) {
          const Component = Components[section?.component];
          return (
            <Component
              key={section?._uid}
              content={section}
              divider={false}
              pb={0}
              first={!disableFirst && (i === firstIndex || (i === 0 && (section?.title || section?.quote)))}
              last={
                i === content?.sections.length - 1 &&
                section?.component === 'contact_form'
              }
              {...props}
            />
          );
        }

        return null;
      })
    : null;
};
