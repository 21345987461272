import { NextSeo, NextSeoProps } from 'next-seo'
import Head from 'next/head'
import { GetStaticPaths } from 'next'
import { useRouter } from 'next/router'
import { defineMessages, useIntl } from 'react-intl'

import { getDescription } from '~lib'

interface SeoProps extends NextSeoProps {
  story?: any
  image?: string
  keywords?: string
  ogType?: 'website' | 'article'
  noIndex?: boolean
  pageHandle?: string
}

const messages = defineMessages({
  title: 'Voodmeals',
})

export const Seo = ({
  story: storyProp,
  title: titleProp,
  description: descriptionProp,
  image: imageProp,
  keywords,
  ogType,
  noIndex,
  pageHandle,
  ...props
}: SeoProps) => {
  const router = useRouter()
  const intl = useIntl()
  const { asPath, basePath, defaultLocale, pathname, locale, locales, query } =
    router

  let title = titleProp
  let description = descriptionProp
  let image = { url: imageProp, width: 1200, height: 627 }

  const story = storyProp?.content || storyProp

  if (story) {
    if (!title) {
      title = story.seo?.title || story.title
    }
    if (!description) {
      description = getDescription(story)
    }
    if (!imageProp) {
      image = {
        url: story?.seo?.og_image || story?.image?.filename,
        width: 1200,
        height: 627,
      }
    }
  }

  const isHome = locales
    .map(l => `/${defaultLocale !== l ? `${l}/` : ''}`)
    .includes(pathname)

  if (title && isHome) {
    title = `Vood - ${title}`
  } else {
    title = title ?? intl.formatMessage(messages.title)
  }

  const isOrderingParamPresent = !!query.ordering === true
  const pathTransformed = asPath === '/' ? '' : asPath
  const serverPathTransformed =
    pageHandle && asPath
      ? asPath.replace('[handle]', pageHandle).replace('[slug]', pageHandle)
      : ''
  let initialAsPath = serverPathTransformed
    ? serverPathTransformed
    : pathTransformed

  const canonical = `https://voodmeals.com${
    locale === defaultLocale ? '' : '/' + locale
  }${initialAsPath}`.split('?')[0]

  return (
    <>
      <NextSeo
        title={title}
        description={description}
        noindex={story?.noIndex === true || noIndex || isOrderingParamPresent}
        openGraph={{
          images: [image],
          type: ogType || 'website',
          locale,
          site_name: 'Vood',
        }}
        {...props}
      />
      <Head>
        <link rel="canonical" href={canonical} />
        {locales
          ?.filter(l => l !== defaultLocale)
          ?.map((content, index) => (
            <meta
              key={content + '_' + index}
              property="og:locale:alternate"
              content={content}
            />
          ))}

        {locales.map(locale => (
          <link
            key={'alternate_' + locale}
            rel="alternate"
            href={`https://voodmeals.com${
              locale === defaultLocale ? '' : `/${locale}`
            }${initialAsPath}`}
            hrefLang={locale}
          />
        ))}

        {!!keywords && <meta name="keywords" content={keywords} />}
      </Head>
    </>
  )
}
